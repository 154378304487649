<script>
    import Tile from './Tile.svelte';

    export let boardSize = 16;
    export let tileMap = [];
    for(let i = 0; i < boardSize; i++) {
        tileMap.push([]);
    };

    let ids = [];
    for(let i = 0; i < boardSize; i++) {
        ids.push(i);
    }

</script>

<div class="board">
    {#each ids as y}
    <div class="row">
        {#each ids as x}
        <Tile bind:this={tileMap[x][y]}/>
        {/each}
    </div>
    {/each}
</div>

<style>
    :root {
        --tile-size: 20px;
    }

    .row {
        height: var(--tile-size);
    }

    .board {
        margin-bottom: 20px;
    }

</style>