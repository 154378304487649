<script>
    import { direction } from './store';
	export let dead;
	export let restartFunc = () => {}
</script>




<div class="{dead ? "hide" : "show"}">
<button on:click="{() => direction.set([0, -1])}"> ^ </button>
<div>
    <button on:click="{() => direction.set([-1, 0])}"> &lt </button>
    <button on:click="{() => direction.set([1, 0])}"> > </button>
</div>
<button on:click="{() => direction.set([0, 1])}"> v </button>
</div>
<div class="{dead ? "show" : "hide"}">
	<button on:click={restartFunc} class = "restart"> Restart </button>
</div>

<style>
	button {
		height: 50px;
		width: 50px;
		margin-left: 20px;
		margin-right: 20px;
		background-color: #ff3e00;
		border: none;
		border-radius: 5px;
	}

	.restart {
		width:unset;
		padding: 10px 20px;
	}

	.hide {
		display:none;
	}

	.show {
		display:block;
	}
</style>