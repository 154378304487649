<script>
	import { direction } from './store';
	import Board from './Board.svelte';
	import BtnControls from './BtnControls.svelte';
	import { onMount } from 'svelte';

	function handleKeydown(event) {
		if (event.isComposing || event.keyCode === 229) {
    		return;
		}

  		if (event.code == 'KeyA' && lastDir[0] != 1) {
			direction.set([-1, 0]);
		} else if(event.code == 'KeyD' && lastDir[0] != -1) {
			direction.set([1, 0]);
		} else if(event.code == 'KeyW' && lastDir[1] != 1) {
			direction.set([0, -1]);
		} else if(event.code == 'KeyS' && lastDir[1] != -1) {
			direction.set([0, 1]);
		}

	}

	function updateSnake(interval) {
		head[0] += $direction[0];
		head[1] += $direction[1];

		let prevTail = snakeTiles[0];
		lastDir = [...$direction];

		let ate = head[0] == food[0] && head[1] == food[1];
		snakeTiles = [...snakeTiles.slice(1 - ate), [...head]]

		snakeTiles.slice(0, -1).forEach((e) => {if(e[0] == head[0] && e[1] == head[1]) dead = true;})
		if(dead || (head[0] > 15 || head[0] < 0 || head[1] > 15 || head[1] < 0)) {
			died(interval);
			return
		};
		tileMap[head[0]][head[1]].setIsSnake(true);

		if(ate) {
			spawnFood();
			clearInterval(interval)
			updater();
		} else tileMap[prevTail[0]][prevTail[1]].setIsSnake(false);
	}

	function updater() {
		const interval = setInterval(() => {
			updateSnake(interval);
		}, 1000/snakeTiles.length);
	}

	function startGame() {
		for(let tileRow of tileMap) {
			for(let tile of tileRow) {
				tile.setIsSnake(false)
				tile.setIsFood(false)
			}
		}

		dead = false;
		head = [...startHead];
		snakeTiles = [...startSnake];
		direction.set([1,0]);
		lastDir = [...$direction]

		for(const st of snakeTiles) {
			tileMap[st[0]][st[1]].setIsSnake(true);
		}
		spawnFood();

		updater();
	}

	function spawnFood() {
		if(food) tileMap[food[0]][food[1]].setIsFood(false);
		let x = Math.floor(Math.random() * boardSize);
		let y = Math.floor(Math.random() * boardSize);

		let foodOnSnake = false;
		snakeTiles.forEach(e => {if(e[0] == x && e[1] == y) foodOnSnake = true});
		while(foodOnSnake) {
			x = Math.floor(Math.random() * boardSize);
			y = Math.floor(Math.random() * boardSize);
			foodOnSnake = false;
			snakeTiles.forEach(e => {if(e[0] == x && e[1] == y) foodOnSnake = true});
		}

		food = [x, y];
		tileMap[food[0]][food[1]].setIsFood(true);
	}

	function died(interval) {
		clearInterval(interval);
		dead = true;
		deathText = `Game Over at Length ${snakeTiles.length}`
	}

	const startHead = [2,0];
	const startSnake = [[startHead[0]-2, startHead[1]], [startHead[0]-1, startHead[1]], [startHead[0], startHead[1]]]
	const boardSize = 16;
	let tileMap;
	let head;
	let snakeTiles;
	let food;
	let lastDir;
	let dead = false;
	let deathText = "";

	onMount( () => {
		startGame();	
	});

</script>

<svelte:window on:keydown={handleKeydown}/>
<main>
	<h1> SNAKE </h1>
	<Board bind:tileMap {boardSize}/>	
	<BtnControls bind:dead restartFunc={() => startGame()}/>
	<h1 class = "{dead ? "show" : "hide"}">{deathText}</h1>
</main>

<style>
	main {
		text-align: center;
		padding: 0;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		font-size: 4em;
		font-weight: 100;
		margin-top: 20px;
	}

	.hide {
		display:none;
	}
	.show {
		display:block;
	}
</style>