<script>
    export let isSnake = false;
    export let isFood = false;
    
    export function setIsSnake(val) {
        isSnake = val;
    }

    export function setIsFood(val){
        isFood = val;
    }

</script>

<div class:isSnake class:isFood/>

<style>
    :root {
        --tile-size: 18px;
    }
    
    div {
        display: inline-block;
        background-color: black;
        width: var(--tile-size);
        height: var(--tile-size);
    }

    .isSnake {
        background-color: yellowgreen;
    }

    .isFood {
        background-color: brown;
    }
</style>